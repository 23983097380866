.HomeHeader-container {
	overflow: hidden;
	margin-top: 15vh;
	width: 100%;
	height: 60vw;
    padding: 2em;
}

.HomeHeader-main-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.HomeHeader-textContainer label {
	color: #61eba7;
}

.HomeHeader-primaryText {
	font-size: 5vw;
	margin: 0;
}

.HomeHeader-secondaryText {
	font-size: 1.8vw;
	font-weight: 500;
	margin: 0;
}

.HomeHeader-button {
	margin: 2vw 0 8vh 0;
	width: 12vw;
	height: 4vw;
	font-size: 1.5vw;
	border-radius: 12vw;
	border: none;
	background-color: #006464;
	color: #ffffff;
	cursor: pointer;
}

.HomeHeader-img1 {
	width: 20vw;
	height: auto;
	border-radius: 100px;
	position: relative;
	bottom: 69vh;
	right: 15vw;
}

.HomeHeader-img2 {
	width: 10vw;
	height: auto;
	border-radius: 150px;
	position: relative;
	bottom: 45vh;
	right: 25vw;
}

.HomeHeader-img3 {
	width: 35vw;
	height: auto;
	border-radius: 100px;
	display: block;
	margin: auto;
}

.HomeHeader-img4 {
	width: 10vw;
	height: auto;
	border-radius: 150px;
	position: relative;
	bottom: 23vh;
	left: 35vw;
}

.HomeHeader-img5 {
	width: 25vw;
	height: auto;
	border-radius: 150px;
	position: relative;
	bottom: 55vh;
	left: 23vw;
}

@media (min-width: 1200px) {
	.HomeHeader-img1 {
		bottom: 70vh;
	}

	.HomeHeader-img2 {
		bottom: 40vh;
	}

	.HomeHeader-img4 {
		bottom: 25vh;
	}

	.HomeHeader-img5 {
		bottom: 70vh;
	}
}

@media (max-width: 950px) {
	.HomeHeader-container {
		height: min-content;
	}

	.HomeHeader-primaryText {
		font-size: 7vw;
	}

	.HomeHeader-secondaryText {
		font-size: 3vw;
	}

	.HomeHeader-button {
		margin: 3vw 0 5vh 0;
		width: 18vw;
		height: 6vw;
		font-size: 2.5vw;
		border-radius: 12vw;
	}

	.HomeHeader-img1 {
		bottom: 25vh;
	}

	.HomeHeader-img2 {
		bottom: 10vh;
	}

	.HomeHeader-img4 {
		bottom: 5vh;
	}

	.HomeHeader-img5 {
		bottom: 18vh;
	}
}

@media (max-width: 650px) {
	.HomeHeader-img1,
	.HomeHeader-img2,
	.HomeHeader-img3,
	.HomeHeader-img4,
	.HomeHeader-img5 {
		display: none;
	}

	.HomeHeader-container {
		padding: 0 10vw 5vw 10vw;
	}

	.HomeHeader-primaryText {
		font-size: 14vw;
	}

	.HomeHeader-secondaryText {
		font-size: 7vw;
	}

	.HomeHeader-button {
		margin: 5vw 0 2vh 0;
		padding: 3vw 6vw;
        width: fit-content;
        height: fit-content;
		font-size: 5vw;
		border-radius: 12vw;
	}
}
