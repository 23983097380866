.plans-container {
	background: linear-gradient(180deg, rgba(97, 235, 167, 0) 0%, rgba(97, 235, 167, 0.5) 100%);
	height: 56vw;
	position: relative;
	padding: 2em;
}
.plans-container > h1 {
	font-size: 4vw;
	color: #006464;
	margin-bottom: 1vw;
}
.plans-container > p {
	font-size: 1.5vw;
	color: #006464;
	padding: 1vw 18vw 2.3vw 18vw;
}
.plans-container > section {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

@media (max-width: 950px) {
	.plans-container {
		height: fit-content;
	}
	
	.plans-container > h1 {
		font-size: 5vw;
	}
	
	.plans-container > p {
		font-size: 2.2vw;
		padding:0 3vw 1vw 3vw;
	}
}

@media (max-width: 650px) {
	.plans-container {
		height: fit-content;
	}

	.plans-container > section {
		flex-direction: column;
		align-items: center;
	}

	.plans-container > h1 {
		font-size: 7vw;
	}
	.plans-container > p {
		font-size: 3.5vw;
	}
}
