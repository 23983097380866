.Facts-section {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 2em;
	box-sizing: border-box;
}

.Facts-title {
	text-align: center;
	font-size: 4vw;
	color: #006464;
}

.Facts-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.Facts-wrapper > .Fact-object {
	flex: 1 1 50%;
	width: 50%;
}

.Fact-object > .Fact-text-section {
	width: 80%;
	margin: auto;
}

.Fact-text-section h4 {
	font-size: 3vw;
	margin: 1vw 0;
}

.Fact-text-section p {
	font-size: 1.5vw;
}

.color-icon {
	color: #61eba6e0;
}

@media (max-width: 950px) {
	.Facts-title {
		font-size: 5vw;
	}

	.Fact-text-section p {
		font-size: 2.2vw;
	}
}

@media (max-width: 650px) {
	.Facts-wrapper > .Fact-object {
		flex: 1 1 90%;
		width: 90%;
	}

	.Facts-title {
		font-size: 7vw;
		margin: auto;
	}

	.Fact-text-section h4 {
		font-size: 5vw;
	}

	.Fact-text-section p {
		font-size: 3.5vw;
	}
}
