.footer-container footer {
	height: 200px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background: linear-gradient(180deg, rgba(97, 235, 167, 0.5) 0%, #61EBA7 100%);
}

.footer-container footer section {
	width: 40%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.footer-container footer section a {
	cursor: pointer;
}

.footer-container footer section img {
	width: 35px;
}

.footer-container footer p {
	color: #000000;
	font-size: 18px;
	margin: 0;
}
