.navbar-container {
	padding: 15px 30px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 1;
}

.navbar-bg {
	background-color: #ffffffea;
}

.navbar-container nav {
	width: 75%;
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar-container nav .default-link img {
	width: 13vw;
}

.navbar-container nav ul {
	padding: 0;
	display: flex;
	justify-content: space-evenly;
	list-style: none;
}

.navbar-container nav ul li {
	margin: 0 1vw;
	text-align: center;
	color: #006464;
	font-size: 1.6vw;
	font-weight: 500;
	cursor: pointer;
	font-family: "Inter-Regular";
}

.navbar-container nav .default-link {
	text-decoration: none;
	display: flex;
	align-items: center;
}

.navbar-container nav > img {
	display: none;
}

.navbar-container nav .mobile-menu {
	display: none;
}

@media (max-width: 950px) {
	.navbar-container {
		padding: 10px 20px;
	}

	.navbar-container nav {
		width: 100%;
		height: 80px;
	}

	.navbar-container nav .default-link img {
		width: 220px;
	}

	.navbar-container nav > img {
		display: inline;
		width: 40px;
		z-index: 1;
	}

	.navbar-container > nav ul {
		display: none;
	}

	.navbar-container nav .mobile-menu {
		width: 100%;
		min-height: fit-content;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #ffffff;
		padding: 3vw 3vw 0 3vw;
		display: flex;
		flex-direction: column;
	}

	.navbar-container nav .mobile-menu img {
		width: 220px;
	}

	.navbar-container nav .mobile-menu ul {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 0;
		padding: 2em 0;
	}

	.navbar-container nav .mobile-menu ul li {
		font-size: 25px;
		margin: 15px 0
	}
}
