.home-container{
	overflow: hidden;
	position: relative;
	background-color: #F7F7F8;
}

.home-color {
	position: absolute;
	z-index: 0;
	background: radial-gradient(50% 50% at 52.81% 50%, #61EBA7 0%, rgba(217, 217, 217, 0) 100%);
}

.home-color-1 {
	width: 80vw;
	height: 40vw;
	left: -60vw;
	top: -1vw;
}

.home-color-2 {
	width: 80vw;
	height: 40vw;
	left: 75vw;
	top: 25vw;
}

.home-color-3 {
	width: 80vw;
	height: 40vw;
	left: -60vw;
	top: 50vw;
}

.home-color-4 {
	width: 80vw;
	height: 40vw;
	left: 75vw;
	top: 120vw;
}

.home-header {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
