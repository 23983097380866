.features-container {
	display: grid;
	grid-template-columns: repeat(2, 2fr);
	grid-template-areas:
		"title title"
		"first second"
		"third forth"
		"fifth sixth";
	padding: 2vw 10vw;
	gap: 2vw;
}

.features-container section:nth-of-type(1) {
	grid-area: title;
}

.features-container section:nth-of-type(2) {
	grid-area: first;
}

.features-container section:nth-of-type(3) {
	grid-area: second;
}

.features-container section:nth-of-type(4) {
	grid-area: third;
}

.features-container section:nth-of-type(5) {
	grid-area: forth;
}

.features-container section:nth-of-type(6) {
	grid-area: fifth;
}

.features-container section:nth-of-type(7) {
	grid-area: sixth;
}

.features-container section:first-of-type {
	text-align: center !important;
	padding: 0;
}

.features-container section:nth-of-type(1) img {
	width: 18vw;
}

.features-container section h5 {
	font-size: 2.4vw;
	margin: 1.6vw 0;
	font-family: "Roboto-Bold";
}

.features-container section:nth-of-type(odd) {
	text-align: left;
}

.features-container section:nth-of-type(even) {
	text-align: right;
}

.features-container section h4 {
	font-size: 3vw;
	margin: 1vw 0;
	font-family: "Roboto-Bold";
}

.features-container section span {
	color: #61eba7;
}

.features-container section p {
	font-size: 1.6vw;
	font-weight: 1000;
	font-family: "Roboto-Bold";
}

.features-container section:not(:first-of-type) img {
	width: 100%;
}

@media (max-width: 650px) {
	.features-container {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas:
			"title"
			"first"
			"second"
			"forth"
			"third"
			"fifth"
			"sixth";
		gap: 0;
		padding: 2em;
	}

	.features-container section:nth-of-type(1) img {
		width: 40vw;
	}

	.features-container section {
		padding: 0;
	}

	.features-container section h5 {
		font-size: 5vw;
	}

	.features-container section h4 {
		font-size: 5vw;
		text-align: left;
        margin-top: 5vw;
	}

	.features-container section p {
		font-size: 3.2vw;
		text-align: left;
	}
}
