.quotationForm-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 2vw;
}

.quotationForm-container > h1 {
	font-size: 1.8vw;
	color: #ffffff;
	text-align: center;
	margin: 10px 0 20px 0;
}

.quotationForm-container fieldset {
	border: none;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0.5em 0;
}

.quotationForm-container fieldset label {
	text-align: start;
	padding: 4px;
	font-size: 1.3vw;
	font-weight: 700;
	color: #ffffff;
}

.quotationForm-container fieldset input {
	height: 3.2vw;
	border-radius: 10px;
	border: 1.5px solid #006464;
	font-size: 1.3vw;
	color: #006464;
	padding: 0px 10px;
}

.quotationForm-container button {
	color: #ffffff;
	background-color: #006464;
	margin: 30px 0 10px 0;
	width: 25vw;
	height: 3.2vw;
	border-radius: 10px;
	font-size: 1.3em;
	cursor: pointer;
	border: 2px solid #ffffff;
}

.quotationForm-container button:disabled {
	opacity: 20%;
	cursor: default;
}

@media (max-width: 950px) {
	.quotationForm-container > h1 {
		font-size: 5vw;
	}

	.quotationForm-container fieldset label {
		padding: 8px;
		font-size: 3vw;
	}
	.quotationForm-container fieldset input {
		height: 6vw;
		font-size: 3vw;
	}

	.quotationForm-container button {
		margin: 30px 0 20px 0;
		width: 50vw;
		height: 6vw;
		font-size: 2em;
	}
}

@media (max-width: 650px) {
	.quotationForm-container > h1 {
		font-size: 6vw;
	}

	.quotationForm-container fieldset label {
		padding: 10px;
		font-size: 4vw;
	}
	.quotationForm-container fieldset input {
		height: 9vw;
		font-size: 4vw;
	}
	.quotationForm-container button {
		margin: 20px 0 20px 0;
		width: 70vw;
		height: 9vw;
		font-size: 2em;
	}
}

@media (max-width: 500px) {
	.contactForm-container button {
		font-size: 1.4em;
	}
}
