.planCard-container {
	width: 25vw;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	background-color: #ffffff;
}

.planCard-container:nth-of-type(1) {
	left: 10vw;
}

.planCard-container:nth-of-type(3) {
	right: 10vw;
}

.planCard-container h3 {
	font-size: 1.6vw;
	padding: 20px 0;
	margin: 0;
	color: grey;
}

.planCard-container img {
	width: 100%;
	height: 20vw;
	object-fit: contain;
}

.planCard-container h2 {
	width: 100%;
	font-size: 1.9vw;
	padding: 15px 0;
	margin: 0;
	background-color: rgb(210, 210, 210);
}

@media (max-width: 950px) {
	.planCard-container {
		width: 28vw;
		position: unset;
	}

	.planCard-container h3 {
		font-size: 3.3vw;
		padding: 15px 0;
	}
	.planCard-container h2 {
		font-size: 3vw;
		padding: 30px 0;
	}
	/* .planCard-container p:nth-of-type(1) {
		font-size: 2vw;
		height: fit-content;
		padding: 0.5vw 1vw;
		margin: 1vw 0;
	} */

	/* .planCard-container p:nth-of-type(2) {
		font-size: 1.8vw;
		padding: 0.5vw 1vw;
		height: fit-content;
	} */

	.planCard-container > div {
		margin: 2.5vw 0 !important;
	}
}

@media (max-width: 650px) {
	.planCard-container {
		width: 85%;
		margin: 3vw 0;
	}

	.planCard-container h3 {
		font-size: 5.5vw;
	}

	.planCard-container img {
	width: 100%;
	height: 60vw;
	object-fit: contain;
}

	.planCard-container h2 {
		font-size: 6vw;
	}

	.planCard-container > div {
		margin: 5vw 0 !important;
	}
}
