.Subscribe-section {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding: 5%;
	background-color: #006464;
	color: #fff;
	position: relative;
}

.Subscribe-section > .Subscribe-object:nth-of-type(1) {
	width: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Subscribe-section > .Subscribe-object:nth-of-type(1) h2 {
	font-size: 2.5vw;
}

.Subscribe-section > .Subscribe-object:nth-of-type(2) {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Subscribe-section > .Subscribe-object:nth-of-type(2) p {
	text-align: center;
	font-size: 1.5vw;
}

.Subscribe-section > .Subscribe-object:nth-of-type(3) {
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Subscribe-text-section {
	text-align: start;
}

.Subscribe-button button {
	border: 1px solid white !important;
}

@media (max-width: 950px) {
	.Subscribe-section {
		padding: 5% 2%;
		gap: 2vw;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(1) {
		width: 30%;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(1) h2 {
		font-size: 2.8vw;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(2) {
		width: 50%;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(2) p {
		font-size: 1.9vw;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(3) {
		width: 20%;
	}
}

@media (max-width: 650px) {
	.Subscribe-section {
		flex-direction: column;
		align-items: center;
	}

	.Subscribe-section > .Subscribe-object {
		padding: 1vw 0;
		width: 100% !important;
		display: flex;
		justify-content: center;
	}

	.Subscribe-section > .Subscribe-object > * {
		width: 90%;
		margin: 1vw;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(1) h2 {
		font-size: 7vw;
	}

	.Subscribe-section > .Subscribe-object:nth-of-type(2) p {
		font-size: 3.8vw;
	}
}
